import React from 'react';
import './MainPage.css';


const LoadStoreButton = ({updatePage}) =>{
    return (
        <p className='MainPageContentText' onClick={updatePage}>Online Store</p>
    )
}

export {LoadStoreButton}