import React from 'react';
import FadeIn from 'react-fade-in';
// import * as Scroll from 'react-scroll';
// import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link, Element } from "react-scroll";



import './MainGallery.css';

import {TopPictures} from './GalleryComponents/TopPictures'
import {LondonGallery} from './GalleryComponents/PlaceGalleries/LondonGallery'
import {ChristmasGallery} from './GalleryComponents/PlaceGalleries/ChristmasGallery'


export class MainGallery extends React.Component{
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         displayGallerySelector: 1,
    //     }
    // };


    render() {
        return (
            <div className='FullScreen'>
                <div className='GalleryTitleContainer'>
                    <p className='GalleryTitle'>Wonky Art Gallery</p> 
                    <p className='galleryToggle' onClick={() => this.props.updateGalleryState()}>homepage</p>
                </div>
                <div className='GalleryContent'>
                    <FadeIn transitionDuration='5000' className='SetWidth'>
                        {/* <TopPictures></TopPictures> */}
                        <FadeIn transitionDuration='5000' className='SetWidth'>
                            {/* <Link activeClass="active"
                                to="section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >Page 1</Link> */}




                            
                            <Element name="test7" className="GalleryContent" id="containerElement">
                                <TopPictures></TopPictures>
                                <p className='MainPageContentText GalleryLinks'>Gallery Links</p>
                                <Link activeClass="active" to="firstInsideContainer" spy={true} smooth={true} duration={250} containerId="containerElement" style={{ display: 'inline-block', margin: '20px' }} className='Links'>
                                    Christmas Gallery
                                </Link>
                                <Link activeClass="active" to="secondInsideContainer" spy={true} smooth={true} duration={250} containerId="containerElement" style={{ display: 'inline-block', margin: '20px' }} className='Links'>
                                    London Gallery
                                </Link>

                                <Element name="firstInsideContainer" style={{
                                marginBottom: '200px'
                                }}>
                                <ChristmasGallery></ChristmasGallery>
                            </Element>

                                <Element name="secondInsideContainer" style={{
                                marginBottom: '200px'
                                }}>
                                <LondonGallery></LondonGallery>
                            </Element>
                            </Element>




                            {/* <ChristmasGallery></ChristmasGallery>
                            <LondonGallery></LondonGallery> */}
                        </FadeIn>
                    </FadeIn>
                    
                    
                </div>
            </div>);
    }
}


export default MainGallery;