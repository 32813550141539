import React from 'react';



// import FadeIn from 'react-fade-in';
import {IndividualPicture} from '../IndividualPicture/IndividualPicture'








// Importing Images
// import Bridge from '../../../../Photos/Bridge.jpeg'
// import BUILDING from '../../../../Photos/BUILDING.jpeg'
// import BUS from '../../../../Photos/BUS.jpeg'
// import Bus2 from '../../../../Photos/Bus2.jpeg'
// import LADY from '../../../../Photos/LADY.jpeg'
// import TelephoneBox from '../../../../Photos/TelephoneBox.jpeg'



import No15 from '../../../../Photos/No15.jpeg'
import No42 from '../../../../Photos/No42.jpeg'
import ROH from '../../../../Photos/ROH.jpeg'
import RAH from '../../../../Photos/RAH.jpeg'
import PotterField from '../../../../Photos/PotterField.jpeg'
import StPancras from '../../../../Photos/StPancras.jpeg'
import BigBen from '../../../../Photos/BigBen.jpeg'
import Trafalgar from '../../../../Photos/Trafalgar.jpeg'
import StPauls from '../../../../Photos/StPauls.jpeg'




export class LondonGallery extends React.Component{
    render() {
        return (
            <div className='maxWidth'>
                <p className='TopPicsMainTitle'>London Collection</p>
                <IndividualPicture img={No15} title='No 15' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='portrait'></IndividualPicture>
                <IndividualPicture img={No42} title='No 42' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='portrait' right='True'></IndividualPicture>
                <IndividualPicture img={ROH} title='Royal Opera House' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='landscape'></IndividualPicture>
                <IndividualPicture img={RAH} title='Royal Albert Hall' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='landscape' right='True'></IndividualPicture>
                <IndividualPicture img={PotterField} title="View from Potter's Field" body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='portrait'></IndividualPicture>
                <IndividualPicture img={StPancras} title='St Pancras' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='portrait' right='True'></IndividualPicture>
                <IndividualPicture img={BigBen} title='Big Ben' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='portrait'></IndividualPicture>
                <IndividualPicture img={Trafalgar} title='Trafalgar Square' body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='landscape' right='True'></IndividualPicture>
                {/* <IndividualPicture img={StPauls} title="Making eyes at St Paul’s" body='Pen and watercolour illustration' date='Framed print £140 - Unframed print £70' orientation='landscape'></IndividualPicture> */}
            </div>
        );
    }
}


export default LondonGallery;