import React from 'react';



// import FadeIn from 'react-fade-in';
import './TopPictures.css';



export class TopPictures extends React.Component{
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         displayGallerySelector: 1,
    //     }
    // };


    render() {
        return (
            <div className='maxWidth'>
                <p className='TopPicsMainTitle'>Top Pick's</p>
                <div className='outerContainter'>
                    <div class="container">
                        <div class="item pic1"></div>
                        <div class="item pic2"></div>
                        <div class="item pic3"></div>
                        <div class="item pic4"></div>
                        <div class="item pic5"></div>
                    </div>
                </div>
            </div>
        );
    }
}


export default TopPictures;