import React from 'react';

export class FullScreenPicture extends React.Component{
    render() {
        return (
            <div>
                <p className='picTitle'>{this.props.orientation}</p>
                <div onClick={this.props.toggleClass} className='IndiviudalPictureImg'
                            style={(this.props.orientation === 'landscape') ? 
                                {backgroundImage: 'url(' + this.props.img + ')', width: '80vw', height:'56.569084995vw',zIndex:10} : 
                                {backgroundImage: 'url(' + this.props.img + ')', width: '56.569084995vw', height:'80vw', zIndex:10}}
                            ></div>
            </div>
        );
    }
}



export default FullScreenPicture;
