import React from 'react';


import './IndividualPictures.css'
import {FullScreenPicture} from './FullScreenPicture.js'
// import FadeIn from 'react-fade-in';
// import './TopPictures.css';

// src='../../../../Photos/Bridge.jpeg' title='The Bridge' body='This is a wonky bridge' date
// import Picture from "./Bridge.jpeg";

export class IndividualPicture extends React.Component{
    constructor(props){
        super(props);
        this.toggleClass= this.toggleClass.bind(this);

        this.state = {
            fullscreen: false,
        }
    };
    toggleClass() {
        const currentState = this.state.fullscreen;
        this.setState({ fullscreen: !currentState });
    };


    // .pic1{
    //     background-image: url('../../../Photos/LADY.jpeg');
    //     /* height: 100%; */
    //     width: auto;
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     background-position: center;
    //     height: 50%;
    // }







    render() {

        let left = (<div className='imgContainter'>
                            <div onClick={this.toggleClass} 
                            style={(this.props.orientation ==='landscape') ? 
                                    {backgroundImage: 'url(' + this.props.img + ')', width: '90%', height:'63.6402206194%'} : 
                                    {backgroundImage: 'url(' + this.props.img + ')', width: '56.569084995%', height:'80%'}}
                            className='IndiviudalPictureImg' ></div>
                    </div>)

        let right = (<div className='descriptionContainer'>
                        <p className='picTitle picWriting'>{this.props.title}</p>
                        <p className='picBody picWriting'>{this.props.body}</p>
                        <p className='picDate picWriting'>{this.props.date}</p>
                    </div>)

        if (this.props.right === "True"){
            right = (<div className='imgContainter'>
                        <div onClick={this.toggleClass}
                        style={(this.props.orientation ==='landscape') ? 
                                {backgroundImage: 'url(' + this.props.img + ')', width: '90%', height:'63.6402206194%'} : 
                                {backgroundImage: 'url(' + this.props.img + ')', width: '56.569084995%', height:'80%'}}
                        className='IndiviudalPictureImg'></div> 
                    </div>)
            left = ((<div className='descriptionContainer'>
                        <p className='picTitle picWriting'>{this.props.title}</p>
                        <p className='picBody picWriting'>{this.props.body}</p>
                        <p className='picDate picWriting'>{this.props.date}</p>
                    </div>))
        }
        let fullscreen = this.state.fullscreen ? <FullScreenPicture toggleClass={this.toggleClass} img={this.props.img} title={this.props.title} orientation={this.props.orientation}></FullScreenPicture> : (<div className='maxWidth ImageContentAndPicContainer'>
            {left}
            {right}
        </div>)

        return (
            <div className='pictureContainterHolder'>
                {fullscreen}
            </div>
        );
    }
}


export default IndividualPicture;


