import React  from 'react';
import './Footer.css';


export class Footer extends React.Component{
    render(){
        return (
            <div className='FooterContainer'>
                <a className='FooterText no-link' href='https://www.instagram.com/wonky.art.series/'>Instagram</a>
                {/* <p className='FooterText'>Contact Us</p> */}
                <a className='FooterText no-link' href="mailto:louisa.spearing@yahoo.co.uk?subject=Wonky Art Series Enquiry&body=Please fill in a detailed description of your enquiry%0d%0a%0d%0aPlease give your name">Contact Us</a>
            </div>
        )
    }
}