import React from 'react';



// import FadeIn from 'react-fade-in';
import {IndividualPicture} from '../IndividualPicture/IndividualPicture'




import Sohoho from '../../../../Photos/Sohoho.jpeg'
import CGC from '../../../../Photos/CoventGardenChristmas.jpeg'

export class ChristmasGallery extends React.Component{

    render() {
        return (
            <div className='maxWidth'>
                <p className='TopPicsMainTitle galleriesTitles'>Christmas Collection</p>
                
                <IndividualPicture img={Sohoho} title='Sohoho' body='Pen and watercolour illustration' date='Cards' orientation='portrait'></IndividualPicture>
                <IndividualPicture img={CGC} title='Covent Garden Christmas' body='Pen and watercolour illustration' date='Cards' orientation='portrait' right='True'></IndividualPicture>



            </div>
        );
    }
}


export default ChristmasGallery;