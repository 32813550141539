
// import './App.css';
import React from 'react';
import './MainPage.css';
// import {GallerySelector} from './Components/GallerySelector'
import {Footer} from '../Footer/Footer'

// import {LoadStoreButton} from './LoadStoreButton'

import { MainGallery } from '../MainGallery/MainGallery';

// import FadeIn from 'react-fade-in';

import {MainPageContent} from './/MainPageContent/MainPageContent'

export class MainPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            displayGallerySelector: 1,
        }
        this.updateGalleryState = this.updateGalleryState.bind(this)
    };

    updateGalleryState(){
        this.setState({ count: this.state.displayGallerySelector += 1 })
    }


    render() {
        let gallerySelector = <MainPageContent updateGalleryState={this.updateGalleryState}></MainPageContent>;
        if (this.state.displayGallerySelector % 2 === 0) {
            // gallerySelector = (<GallerySelector></GallerySelector>);
            gallerySelector = (<MainGallery updateGalleryState={this.updateGalleryState}></MainGallery>);
        } else {
            gallerySelector = (<MainPageContent updateGalleryState={this.updateGalleryState}></MainPageContent>);
        }


        return (
            <div className='MainPage'>
                { gallerySelector }
            {/*      { gallerySelector }
                 <div className='MainPageBody'>
                     <div className='MainTitleContainer'>
                         <h1 className='MainTitle'>Wonky Art</h1>
                     </div>
                     <FadeIn transitionDuration='5000' className='SetWidth'>
                         <div className='MainPageContent'>
                             <div className='MainPageContentContainer ViewGallerySelector'>
                                 <p className='MainPageContentText' onClick={() => this.setState({ count: this.state.displayGallerySelector += 1 })}>View the Gallery</p>
                                
                             </div>
                             <FadeIn transitionDuration='5000' className='SetWidth MainPageContentContainer'>
                                 <div className='MainPageContentContainer'>
                                    //   <p className='MainPageContentText' onClick={updatePage}>Online Store</p> 
                                     <LoadStoreButton updatePage={() => {this.props.updatePage('storePage')}}></LoadStoreButton>
                                 </div>
                             </FadeIn>
                             <FadeIn transitionDuration='10000' className='SetWidth MainPageContentContainer'>
                                 <div className='MainPageContentContainer'>
                                    //   <p className='MainPageContentText'>Enquiries</p> 
                                     <a className='MainPageContentText' href="mailto:louisa.spearing@yahoo.co.uk?subject=Wonky Art Series Enquiry&body=Please fill in a detailed description of your enquiry%0d%0a%0d%0aPlease give your name">Email Enquiries</a>
                                 </div>
                             </FadeIn>
                         </div>
                     </FadeIn>
                 </div> */}
                    <Footer></Footer>
            </div>

        );
    }
}

export default MainPage;
