import './App.css';

// import {PageRenderer} from './Components/Pages/PageRenderer/PageRenderer';
import {MainPage} from './Components/Pages/MainPage/MainPage'






function App() {
  return (
    <div className='website'>
      <div className='canvas'>
        <MainPage></MainPage>
      </div>
    </div>

  );
}

export default App;
