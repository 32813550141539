
// import './App.css';
import React from 'react';
import '../MainPage.css';
// import {Footer} from '../../Footer/Footer'

import { LoadStoreButton } from '../LoadStoreButton';


import FadeIn from 'react-fade-in';


export class MainPageContent extends React.Component{
    render() {
        return (
            // <div>  //className='MainPage'>
            <div className='MainPageBody'>
                <div className='MainTitleContainer'>
                    <h1 className='MainTitle'>Wonky Art</h1>
                </div>
                <FadeIn transitionDuration='5000' className='SetWidth'>
                    <div className='MainPageContent'>
                        <FadeIn transitionDuration='0' className='SetWidth MainPageContentContainer'>
                            <div className='MainPageContentContainer ViewGallerySelector'>
                                <p className='MainPageContentText' onClick={() => this.props.updateGalleryState()}>View the Gallery</p>
                                
                            </div>
                        </FadeIn>
                        <FadeIn transitionDuration='5000' className='SetWidth MainPageContentContainer'>
                            <div className='MainPageContentContainer'>
                                {/* <p className='MainPageContentText' onClick={updatePage}>Online Store</p> */}
                                <LoadStoreButton updatePage={() => {this.props.updatePage('storePage')}}></LoadStoreButton>
                            </div>
                        </FadeIn>
                        <FadeIn transitionDuration='10000' className='SetWidth MainPageContentContainer'>
                            <div className='MainPageContentContainer'>
                                {/* <p className='MainPageContentText'>Enquiries</p> */}
                                <a className='MainPageContentText' href="mailto:louisa.spearing@yahoo.co.uk?subject=Wonky Art Series Enquiry&body=Please fill in a detailed description of your enquiry%0d%0a%0d%0aPlease give your name">Email Enquiries</a>
                            </div>
                        </FadeIn>
                    </div>
                </FadeIn>
            </div>
                    /* <Footer></Footer>
            </div> */

        );
    }
}

export default MainPageContent;